<template>
	<div class="cbox">
		<div class="headbar">
			<div style="display: flex;">

				<el-input v-model="keyword" placeholder="标题关键字" style="max-width: 200px; margin-right: 10px;" />

				<el-button type="primary" plain size="small" icon="el-icon-search" @click="onSearch">搜索</el-button>

				<el-button type="primary" size="small" icon="el-icon-plus" @click="add">添加分数</el-button>


				<el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
					<a href="/xls/历年分数导入模板.xlsx" style="text-decoration: none;color:inherit">下载历年分数导入模板</a>
				</el-button>

				<el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
					:on-success="handleXlsUpload" :show-file-list="false" name="image">
					<el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">导入历年分数</el-button>
				</el-upload>


			</div>

		</div>
		<div class="bcontent">


			<el-table :data="DataList" stripe border>

				<el-table-column type="index" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="lqtype" label="类型" width="100">
				</el-table-column>
				<el-table-column prop="lqyear" label="年份" width="80">
				</el-table-column>
				<el-table-column prop="province" label="省份" width="100">
				</el-table-column>
				<el-table-column prop="kelei" label="科类" width="100">
				</el-table-column>
				<el-table-column prop="pici" label="批次" width="120">
				</el-table-column>
				<el-table-column prop="zhuanye" label="专业名称" min-width="180">
				</el-table-column>
				<el-table-column prop="lq_num" label="录取数" width="100">
				</el-table-column>
				<!-- <el-table-column prop="shengkongxian" label="省控线" width="100">
				</el-table-column> -->
				<!-- <el-table-column prop="score_max" label="最高分" width="100">
				</el-table-column> -->
				<el-table-column prop="score_min" label="最低分" width="100">
				</el-table-column>
				<el-table-column prop="min_score_rank" label="最低分排名" width="100">
				</el-table-column>
			<!-- 	<el-table-column prop="beizhu" label="备注" min-width="180">
				</el-table-column> -->

				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">

						<el-button type="text" @click="updateDetail(scope.row)">编辑</el-button>
						<el-button type="text" style="color: orangered;" @click="remove(scope.row.id)">删除</el-button>
						<!--
			<el-button type="text" @click="showTj(scope.row)">统计</el-button> -->


					</template>
				</el-table-column>


			</el-table>
		</div>
		<div style="padding-top: 10px;display: flex; flex-direction: row-reverse;">

			<el-pagination background layout="prev, pager, next" :total="page.count" :current-page="page.current_page"
				:page-size="20" @current-change="paginate">
			</el-pagination>

		</div>




		<el-dialog title="分数详情" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px">
			<el-form ref="form" :model="EditItem" label-width="80px">

				<el-row>
					<el-col :span="12">
						<el-form-item label="类型">

							<el-input v-model="EditItem.lqtype"></el-input>

						</el-form-item>

					</el-col>

					<el-col :span="12">
						<el-form-item label="年份">

							<el-input v-model="EditItem.lqyear"></el-input>

						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="省份">

							<el-input v-model="EditItem.province"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="科类">

							<el-input v-model="EditItem.kelei"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="批次">

							<el-input v-model="EditItem.pici"></el-input>

						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="专业名称">

							<el-input v-model="EditItem.zhuanye"></el-input>

						</el-form-item>
					</el-col>
					
					<el-col :span="8">
						<el-form-item label="录取数">
					
							<el-input v-model="EditItem.lq_num"></el-input>
					
						</el-form-item>
					</el-col>
					
					<el-col :span="8">
						<el-form-item label="省控线">

							<el-input v-model="EditItem.shengkongxian"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="最高分">

							<el-input v-model="EditItem.score_max"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="最低分">

							<el-input v-model="EditItem.score_min"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="平均分">

							<el-input v-model="EditItem.score_avg"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="备注">

							<el-input v-model="EditItem.beizhu"></el-input>

						</el-form-item>
					</el-col>


					<el-col :span="24">
						<div style="text-align: center; padding: 50px;">

							<el-button type="primary" @click="saveForm">保存</el-button>
						</div>
					</el-col>
				</el-row>



			</el-form>
		</el-dialog>





	</div>
</template>
  
<script>

export default {
	components: {

	},
	data() {
		return {
			dialogVisible: false,
			EditItem: {
				id: 0,
				lqtype: "",
				lqyear: "",
				province: "",
				kelei: "",
				pici: "",
				zhuanye: "",
				shengkongxian: "",
				score_max: "",
				score_min: "",
				score_avg: "",
				beizhu: "",
				lq_num:""
			},
			DataList: [],
			keyword: "",
			page: {
				current_page: 1,
				next_page: 1,
				pre_page: 1,
				total_page: 1,
				count: 0
			},

		};
	},

	mounted() {
		this.getList();


	},
	methods: {
		onSearch() {
			this.getList();
		},
		getList(page) {
			this.$http.post("/api/score_list", {
				keyword: this.keyword,
				page: this.page.current_page
			}).then(
				res => {
					this.DataList = res.data.data;
					this.page = res.data.page

				})
		},

		paginate(val) {
			this.page.current_page = val
			this.getList()
		},

		add() {
			this.EditItem = {
				id: 0,
				lqtype: "",
				lqyear: "",
				province: "",
				kelei: "",
				pici: "",
				zhuanye: "",
				shengkongxian: "",
				score_max: "",
				score_min: "",
				score_avg: "",
				beizhu: "",
				lq_num:""
			},
				this.dialogVisible = true
		},
		updateDetail(e) {
			this.EditItem = e
			this.dialogVisible = true
		},
		handleXlsUpload(e) {

			this.$http.post("/api/import_score_xls", {
				url: e.src
			}).then(res => {
				this.$message({
					type: 'success',
					message: '导入成功'
				});

				this.getList();
			})
		},

		remove(id) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/score_delete", {
					id: id
				}).then(res => {
					this.$message({
						type: 'success',
						message: '删除成功'
					});

					this.getList(this.page.current_page);
				})

			}).catch(() => {

			});
		},
		saveForm() {
			this.$http.post("/api/score_update", this.EditItem).then(res => {
				this.$message({
					type: 'success',
					message: '保存成功'
				});
				this.dialogVisible = false

				this.getList();
			})
		}


	}
}
</script>
  